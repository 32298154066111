import { v4 as uuid } from 'uuid';
import { merge } from 'lodash';
import { Configuration } from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';

export const buildConfiguration = (configuration: Partial<Configuration> = {}): Configuration =>
  merge(
    {
      id: uuid(),
      revision: '1',
      ownerDisplayName: 'Store Owner',
      body: {
        enabled: false,
        required: false,
      },
      title: {
        enabled: false,
        required: false,
      },
      media: {
        enabled: false,
        required: false,
      },
      customFieldNames: {},
      moderationRules: {
        wordsFilter: {
          words: [],
          active: false,
        },
        ratingValues: {
          ratingValues: [],
          active: false,
        },
        mediaFilter: {
          active: false,
        },
      },
      notificationSettings: {
        reviewReminder: {
          active: false,
          afterSeconds: 0,
        },
        newReviewBMNotification: {
          active: false,
        },
      },
      helpfulVotes: {
        active: false,
        helpfulVotesOnly: false,
      },
    },
    configuration,
  );
