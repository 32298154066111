import { VoidReturn } from '~/ts-utils';
import { fetchInitialData, createReview, requestLoginAction } from '../common/store/actions';
import { navigateToThankYou } from '../common/store/actions/navigation-actions';
import {
  initializeBaseParams,
  setBaseParams,
} from '../common/store/base-params/base-params-actions';

export const controllerActions = {
  fetchInitialData,
  createReview,
  setBaseParams,
  navigateToThankYou,
  requestLoginAction,
  initializeBaseParams,
};

export type ControllerActions = {
  [key in keyof typeof controllerActions]: VoidReturn<(typeof controllerActions)[key]>;
};
