import { AppThunk } from '../types';
import { REVIEWS_APP_ID, THANK_YOU_PAGE_ID } from '~/app-ids';
import { assertDefined } from '~/ts-utils';
import { ThankYouView } from '~commons/types';

export const navigateToThankYou =
  (ctaLink: string, view?: ThankYouView): AppThunk =>
  async (_dispatch, _getState, { wixCodeApi }) => {
    const section = await wixCodeApi.site.getSectionUrl(
      { sectionId: THANK_YOU_PAGE_ID, appDefinitionId: REVIEWS_APP_ID },
      false,
    );

    assertDefined(section.relativeUrl);

    const destinationUrl = new URL(section.relativeUrl, 'http://wix.com/');
    destinationUrl.searchParams.append('ctaLink', encodeURIComponent(ctaLink));
    view && destinationUrl.searchParams.append('view', view);
    return wixCodeApi.location.to!(destinationUrl.pathname + destinationUrl.search);
  };
