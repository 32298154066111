import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { isSledRun } from './services/is-sled-run';

export const getEnvParams = (wixCodeApi: IWixAPI) => ({
  isSSR: wixCodeApi.window.rendering.env === 'backend',
  isDebug: !!wixCodeApi.location.query.debug,
  logNetwork: isSledRun(wixCodeApi.location)
    ? ('all' as const)
    : wixCodeApi.location.query.debug
    ? ('error' as const)
    : ('none' as const),
});
