import { combineReducers } from 'redux';
import { formReducer } from './form/form-reducer';
import { baseParamsReducer } from './base-params/base-params-reducer';
import { appStateReducer } from './app-state/app-state-reducer';

export const rootReducer = combineReducers({
  appState: appStateReducer,
  form: formReducer,
  baseParams: baseParamsReducer,
});
