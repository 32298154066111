import { FormState } from './form-types';
import { AllActions } from '../types';
import { CREATE_REVIEW_CANCEL, CREATE_REVIEW_FAILURE, CREATE_REVIEW_REQUEST } from '../actions';

export const formReducer = (
  state: FormState = { status: 'IDLE' },
  action: AllActions,
): FormState => {
  switch (action.type) {
    case CREATE_REVIEW_REQUEST:
      return { status: 'PUBLISHING' };
    case CREATE_REVIEW_CANCEL:
      return { status: 'IDLE' };
    case CREATE_REVIEW_FAILURE:
      return { status: 'ERROR', errorType: action.payload };
    default:
      return state;
  }
};
