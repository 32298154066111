import { IWixAPI } from '@wix/yoshi-flow-editor';
import { noop } from 'lodash';
import { LoginModes } from '~commons/constants/login-modes';

export const requestLogin = async (wixCodeApi: IWixAPI) => {
  const options: any = {
    lang: wixCodeApi.site.language,
    modal: true,
  };
  options.mode = LoginModes.login;
  try {
    return wixCodeApi.user.promptLogin(options);
  } catch (e) {
    return noop(e);
  }
};
