import { FetchInitialData } from './api';
import { buildProduct } from '~commons/testing/builders/product-builders';
import { buildConfiguration } from '~commons/testing/builders/configuration-builder';
import { ApplicationError } from '~/components/CollectionWidget/common/types';

export const fetchInitialDataEditorMock: FetchInitialData = async () => {
  const product = buildProduct();
  if (!product || !product.id || !product.url || !product.name) {
    return {
      type: 'error',
      error: new ApplicationError('Missing required product data!'),
    };
  }
  return {
    type: 'ok',
    value: {
      entity: {
        id: product.id,
        name: product.name,
        imageUrl: product.imageUrl ?? undefined,
        url: product.url,
      },
      configuration: buildConfiguration({
        title: {
          maxLength: 100,
        },
        body: {
          maxLength: 500,
        },
        media: {
          limit: 5,
        },
      }),
      currentMember: undefined,
      existingUserReview: undefined,
    },
  };
};
