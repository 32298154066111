import { AmbassadorHTTPError } from '@wix/ambassador/dist/src/runtime/http';
import { FormErrorType } from './form-types';

export const toFormError = (error: AmbassadorHTTPError): FormErrorType => {
  switch (error?.response?.status) {
    case 403:
      return error.response?.data?.details?.applicationError?.code ===
        'VisitorContactBelongsToMember'
        ? 'CONTACT_BELONGS_TO_MEMBER'
        : 'PERMISSION_DENIED';
    case 409:
      return 'REVIEW_ALREADY_EXISTS';
    case 428:
      return error.response?.data?.details?.applicationError?.code === 'RequestLinkDoesNotExists'
        ? 'INVALID_REQUEST_LINK'
        : 'UNKNOWN';
    default:
      return 'UNKNOWN';
  }
};
