import { WidgetProps } from '@wix/yoshi-flow-editor';
import { State } from './store/types';
import { ControllerActions } from '../controller/controller-actions';
import { ReviewCreateError as ReviewCreateErrorCommon } from '~commons/types';
import { CollectionWidgetTranslationKey } from '../locale-types';

type CollectionProps = { state: State; actions: ControllerActions };

export type CollectionWidgetProps = WidgetProps<CollectionProps>;

export type ReviewCreateError = ReviewCreateErrorCommon<CollectionWidgetTranslationKey>;

type Tags = Record<string, string | undefined | null>;
export class ApplicationError extends Error {
  public tags: Tags;

  constructor(message: string, tags: Tags = {}) {
    super(message);
    this.tags = tags;
  }
}
