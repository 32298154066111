import { BaseParamsState } from './base-params-types';
import { AllActions } from '../types';
import { SET_BASE_PARAMS, SET_INSTANCE } from './base-params-actions';

export const baseParamsReducer = (
  state: BaseParamsState = { origin: { type: 'UNKNOWN' } },
  action: AllActions,
): BaseParamsState => {
  switch (action.type) {
    case SET_BASE_PARAMS:
      return action.payload;
    case SET_INSTANCE:
      return {
        ...state,
        instance: action.payload,
      };
    default:
      return state;
  }
};
