import { v4 as uuid } from 'uuid';
import { merge } from 'lodash';
import { Product } from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';

export const buildProduct = function (product: Partial<Product> = {}): Product {
  return merge(
    {
      id: uuid(),
      name: "I'm a Product",
      imageUrl:
        'https://static.wixstatic.com/media/22e53e_efc1552d8050407f82ea158302d0debd~mv2.jpg',
      url: 'https://lukask6.wixsite.com/my-site-114/product-page/i-m-a-product',
      price: {
        value: '15.00',
        currency: 'USD',
      },
    },
    product,
  );
};
